/*eslint-disable sonarjs/cognitive-complexity*/
import React from "react";
import { IDeleteModalProps } from "./deleteModal.types";
import { DeleteItemModalBase } from '../../files/deleteItemModal/deleteItemModal.base';
import { DeleteArchiveModalBase } from "../../files/deleteArchiveModal/deleteArchiveModal.base";
import { showDeleteModal, useArchiveContent } from "../../../features/archiveContent";
import { DownloadFilesModalBase } from "../../files/downloadFilesModal/downloadFilesModal.base";
import { useCurrentArchive } from "../../../hooks/useCurrentArchive";
import { FileApprovalStatus } from "../../../models/archiveItem";
import { ArchiveStatusFilesApproval } from "../../../models/constants";
import { useSearchContent } from "../../../features/searchContent";
import { DownloadableItem, startDownload } from "../../../features/downloadStatus";
import { archivesApi } from "../../../services/archives/archives.api";
import { nanoid } from "@reduxjs/toolkit";
import { useDocLabDispatch } from "../../../docLabStore";
import { MoveToRecycleBinFilesBase } from "../../files/moveToRecycleBinFiles/moveToRecycleBinFiles.base";
import { RestoreFromRecycleBinBase } from "../../files/restoreFromRecycleBin/restoreFromRecycleBin.base";

const MODAL_COMPONENTS: { [key: string]: (props: IDeleteModalProps) => JSX.Element } = {
    DELETE_ITEM: DeleteItemModalBase,
    DELETE_ARCHIVE: DeleteArchiveModalBase,
    DOWNLOAD_FILES: DownloadFilesModalBase,
    MOVE_TO_RECYCLEBIN: MoveToRecycleBinFilesBase,
    RESTORE_FROM_RECYCLEBIN: RestoreFromRecycleBinBase
};

export const DeleteModalBase = (props: IDeleteModalProps) => {
    const { deleteModalType, deleteModalShown, selectedItems, items } = useArchiveContent();
    const currentArchive = useCurrentArchive();
    const { selectionEntryList } = useSearchContent();
    const dispatch = useDocLabDispatch();
    const selectionList = selectedItems.length > 0 ? selectedItems : selectionEntryList;

    if (deleteModalType !== undefined) {
        const flag = currentArchive?.filesApproval === ArchiveStatusFilesApproval.Enabled && selectionList.some(s => s.approvalStatus === FileApprovalStatus.ToBeApproved || s.approvalStatus === FileApprovalStatus.Rejected);
        if (selectionList.length > 1 || flag || deleteModalType !== "DOWNLOAD_FILES" || selectionList[0].isFolder) {
            const DeleteItemModal = MODAL_COMPONENTS[deleteModalType];
            return <DeleteItemModal {...props} />
        }

        if (selectionList.length === 1 && deleteModalShown === true  && !selectionList[0].isFolder) {
            const file = selectedItems.length > 0 && items.length > 0 ?
                items.find(item => item.id === selectionList[0].id)
                :
                {
                    id: selectionList[0].id,
                    name: selectionList[0].name ?? ""
                } as DownloadableItem;

            if (file) {
                dispatch(startDownload({
                    requestId: nanoid(),
                    file,
                    downloadFunction: archivesApi.downloadFile
                }));
                dispatch(showDeleteModal({ show: false }));
                return null;
            }
        }
    }
    return null;
}