/*eslint-disable sonarjs/cognitive-complexity*/
import React, { useEffect, useState } from "react";
import { showDeleteModal, useArchiveContent } from "../../../features/archiveContent";
import { useDocLabDispatch } from "../../../docLabStore";
import { useTranslation } from "react-i18next";
import { IDeleteModalProps } from "../../common/deleteModal/deleteModal.types";
import { DeleteModalLayoutBase } from "../../common/deleteModal/deleteModalLayout";
import { archivesApi } from "../../../services/archives/archives.api";
import { useAsyncApi } from "../../../../utilities/hooks";
import { useSearchContent } from "../../../features/searchContent";
import { FileApprovalStatus } from "../../../models/archiveItem";
import { DownloadableItem, startDownload } from "../../../features/downloadStatus";
import { nanoid } from "@reduxjs/toolkit";

export const DownloadFilesModalBase = (props: IDeleteModalProps) => {
  const { deleteModalShown, selectedItems } = useArchiveContent();
  const { selectionEntryList } = useSearchContent();
  const listEnableToDownload = selectedItems.length > 0 ? selectedItems : selectionEntryList;
  const selectionList = selectedItems.length > 0 ? selectedItems : selectionEntryList;
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDocLabDispatch();
  const { t } = useTranslation(['downloadFilesModal', 'common']);

  const singleDownload = () => {
    if(!selectionList[0].isFolder)
    {
      const file = {
        id: selectionList[0].id,
        name: selectionList[0].name ?? '',
      } as DownloadableItem;

      dispatch(startDownload({
        requestId: nanoid(),
        file,
        downloadFunction: archivesApi.downloadFile,
      }));
      props.onClose && props.onClose();
    }
  }

  const { execute: downloadFiles, loading, error: err } = useAsyncApi<void, void>({
    func: async () => {
      const fileIds = listEnableToDownload.filter(i => !i.isFolder).map(x => x.id);
      const folderIds = listEnableToDownload.filter(i => i.isFolder).map(x => x.id);

      const response = await archivesApi.multipleDownload(fileIds, folderIds);

      const data = new Blob([response], { type: 'application/octet-stream' });
      const objectUrl: string = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = objectUrl;

      const d = new Date();
      const datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "_" + ("0" + d.getHours()).slice(-2) + "-" + ("0" + d.getMinutes()).slice(-2) + "-" + ("0" + d.getSeconds()).slice(-2);
      tempLink.setAttribute('download', "EDI_" + datestring + '.zip');
      document.body.appendChild(tempLink);
      tempLink.click();

      props.onClose && props.onClose();
    }
  });

  useEffect(() => {
    if (!err) {
      setErrorMessage("");
      return;
    }
    setErrorMessage(t("common:genericErrorApi"));
  }, [err]);  //eslint-disable-line react-hooks/exhaustive-deps

  const title = selectionList.length > 1 ? 'title' : 'titleMonoDownload';
  const subTextDownload = selectionList.length > 1 ?
    selectionList.some(s => s.approvalStatus === FileApprovalStatus.Rejected || s.approvalStatus === FileApprovalStatus.ToBeApproved)
      ? 'subtextMultiploWarning' : 'subtext' : 'subTextDownloadMono';

  return (
    <DeleteModalLayoutBase
      {...props}
      Title={loading ? t('downloading') : t(title)}
      onDismiss={() => { dispatch(showDeleteModal({ show: false })) }}
      SubText={loading ? t('longTime') : t(subTextDownload)}
      onClick={(selectionList.length === 1 && !selectionList[0].isFolder) ? singleDownload :  downloadFiles}
      PrimaryButtonText={loading ? "" : t('buttonConfirm')}
      DefaultButtonText={loading ? "" : t('buttonCancel')}
      IsOpen={deleteModalShown}
      ErrorMessage={errorMessage}
      Disabled={loading}
    />
  )
}